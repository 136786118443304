/**
* Auth Styles
* ============================================================================
*/

.auth {
  h3 {
    font-size: 24px;
    margin: 10px 0 30px;
  }

  .form-actions {
    margin-top: 20px;
  }

  .form-actions-samlidp {
    margin: 10px 0;
  }
}

section.org-login {
  h3 {
    margin: 0 0 20px;
  }
  .org-avatar {
    width: 56px;
    height: 56px;
    border-radius: 3px;
    margin-bottom: 20px;
  }
}

.provider-logo {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: text-top;
  margin-right: 4px;
  border-radius: 2px;
  position: relative;
  top: 1px;
  background-size: cover;

  &.google {
    background-image: url(~sentry-logos/logo-google.svg);
  }

  &.github {
    background-image: url(~sentry-logos/logo-github.svg);
  }

  &.onelogin {
    background-image: url(~sentry-logos/logo-onelogin.svg);
  }

  &.auth0 {
    background-image: url(~sentry-logos/logo-auth0.svg);
  }

  &.okta {
    background-image: url(~sentry-logos/logo-okta.svg);
  }

  &.jumpcloud {
    background-image: url(~sentry-logos/logo-jumpcloud.svg);
  }

  &.rippling {
    background-image: url(~sentry-logos/logo-rippling.svg);
  }

  &.active-directory {
    background-image: url(~sentry-logos/logo-microsoft.svg);
  }

  &.saml2 {
    background-image: url(~sentry-logos/logo-saml2.svg);
  }

  &.slack {
    background-image: url(~sentry-logos/logo-slack.svg);
  }

  &.vsts {
    background-image: url(~sentry-logos/logo-azure.svg);
  }

  &.fly {
    background-image: url(~sentry-logos/logo-fly.svg);
  }
}

.btn-default > .provider-logo {
  margin-right: 8px;
}

.sso-link {
  text-align: center;
  margin-bottom: 24px;

  .avatar {
    width: 36px;
    height: 36px;
  }
}

.sso-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 15px;

  &.flex {
    grid-template-columns: auto auto;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .left-column {
    text-align: right;
  }

  .right-column {
    text-align: left;
  }

  .avatar-row {
    margin: 4px 0 16px;
  }

  .email-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 270px;
  }
}

// 2FA

div.u2f-box {
  position: relative;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;

  div.device-animation-frame {
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    border: 1px solid darken(@trim, 5);
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;
  }

  div.loading-dots {
    position: absolute;
    top: 60%;
    left: 50%;
    margin-left: -25px;
    margin-top: -9px;
    opacity: 0;
    transition: all ease-in 0.4s;

    .dot {
      display: inline-block;
      width: 14px;
      height: 14px;
      background: @blue;
      border-radius: 50%;
      margin-right: 4px;

      //Animate
      animation-name: blink;
      animation-duration: 1.6s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;

      &:nth-child(2) {
        animation-delay: 0.4s;
      }

      &:nth-child(3) {
        animation-delay: 0.8s;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  div.device-animation {
    background: url(../images/spot/u2f-small.gif);
    background-size: 100px 100px;
    width: 100px;
    height: 100px;
    transition: opacity 0.3s;
  }

  div.device-failed {
    display: none;
    position: relative;
    width: 100px;
    height: 100px;

    text-align: center;
    font-size: 46px;
    font-weight: bold;
    line-height: 100px;
    color: @red;
    &:after {
      content: ':-(';
    }
  }

  div.inner {
    margin: 0 auto;
    padding-top: 20px;
    max-width: 360px;

    p {
      margin: 0;
    }
  }

  div.failure-message {
    p {
      margin: 0 0 10px 0;
    }
  }

  &.tapped {
    .loading-dots {
      opacity: 1;
      top: 50%;
      display: block;
    }
    .device-animation {
      -webkit-filter: blur(4px);
      filter: blur(4px);
    }
  }

  &.device-failure {
    .device-animation {
      visibility: hidden;
    }
    .device-failed {
      display: block;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
